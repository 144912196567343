import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
    PoolProps,
    UpgradeTierDrawerProps,
    UpgradeTierStage,
} from '@decub8/ui';
import { TierColors } from '@decub8/ui/dist/molecules/types';
import { commify } from '@ethersproject/units';
import { log } from '@logtail/next';
import dayjs from 'dayjs';

import {
    filterPoolsByStakingVersions,
    getPoolItem,
} from '@src/components/Staking/StakingPools/hooks';
import { CONTENT } from '@src/config';
import { BaseTokenSymbol } from '@src/contracts/index';
import { setStakingModalOpen } from '@src/features/layout';
import {
    setCurrentPool,
    setDepositAmount,
    setToken1DepositAmount,
    updatePool,
} from '@src/features/staking';
import { useAppDispatch, useAppSelector } from '@src/hooks';
import { GlobalContext } from '@src/hooks/useGlobalContext';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';
import { ContractType } from '@src/ts/constants';
import { StakingPool } from '@src/ts/interfaces';
import {
    getMinReferrerTier,
    getMinTokensForTierWithNoMin,
} from '@src/utils/getters';
import { getStakingVersions } from '@src/utils/staking';

import { getTierInfoForUpgradeDrawer, tierColorUI } from './utils';

export const useUpgradeTier = (): UpgradeTierDrawerProps => {
    const {
        _userTier,
        _tiers,
        _tierDrawerOpen,
        _setRequiredDCB,
        _setTierDrawerOpen,
        _setTierUpgradeStage,
        _tierUpgradeStage,
        _requiredDCB,
        contract_manager,
    } = useContext(GlobalContext);

    const { account } = useWeb3Onboard();
    const [min_referrer_tier, setMinReferrerTier] = useState(0);
    const [min_tokens_for_tier_no_min, setMinTokensForTierNoMin] = useState(0);
    const { pools } = useAppSelector((state) => state?.staking);

    useEffect(() => {
        getMinTokensForTierWithNoMin()
            .then((min) => setMinTokensForTierNoMin(min))
            .catch((err) =>
                log.error(`Error getting min tokens for tier with no min`, err),
            );
        getMinReferrerTier()
            .then((tier) => setMinReferrerTier(tier))
            .catch((err) => log.error(`Error getting min referrer tier`, err));
    }, []);

    const current_tier = _userTier?.flag ? _userTier : null;

    const is_max = _userTier?.id === _tiers?.length - 1;
    const initial_multiplier_val = is_max ? _userTier?.multiplier + 1 : 1;
    const [value, setValue] = useState(initial_multiplier_val);
    const dispatch = useAppDispatch();

    // gets the data displayed in the upgrade tier drawer for each tier
    const tier_info = useMemo(() => {
        const filtered_tiers =
            _tiers && _tiers.length > 0
                ? _tiers?.filter((t) =>
                      current_tier
                          ? is_max
                              ? t.id === current_tier?.id
                              : t.id > current_tier?.id
                          : true,
                  )
                : [];

        return filtered_tiers?.map(
            ({ id, name, image_url, min_limit, refund_fee }) =>
                getTierInfoForUpgradeDrawer(
                    _tiers,
                    id,
                    name,
                    image_url,
                    min_limit,
                    _userTier?.power, // or power?
                    _userTier?.actual_amount,
                    value,
                    refund_fee,
                    min_referrer_tier,
                    _setRequiredDCB,
                    _setTierUpgradeStage,
                    min_tokens_for_tier_no_min,
                ),
        );
    }, [
        _tiers,
        is_max,
        current_tier,
        _userTier,
        value,
        dispatch,
        min_referrer_tier,
    ]);

    const handleClose = useCallback(() => {
        _setTierDrawerOpen(false);
        _setTierUpgradeStage(UpgradeTierStage.Tier);
        setValue(initial_multiplier_val);
    }, [initial_multiplier_val]);

    const filtered_by_version = useMemo(
        () => filterPoolsByStakingVersions(pools, getStakingVersions(false)),
        [pools],
    );

    // handles selecting a pool to stake in from the upgrade tier drawer
    const handleSelectPoolInUpgradeTierDrawer = useCallback(
        (pool: StakingPool) => {
            // get the base token address for the client on the correct chain i.e DCB, AITECH, SIDUS
            const base_token = contract_manager
                .getContractAddress(ContractType.BaseToken, pool.chain_id)
                .toLowerCase();

            // if the pool is a liquidity pool, we need to set the deposit amount for the correct token
            const is_liquidity = ['legacy-liquidity', 'liquidity'].includes(
                pool.type,
            );
            const setDepositAmountHandler =
                is_liquidity &&
                pool.input_token[1]?.address.toLowerCase() === base_token // need to set second token deposit amount if it is the base token
                    ? setToken1DepositAmount
                    : setDepositAmount;

            // percentage increase to a users tier from staking in the pool
            const r = pool.tier_boost / 100;
            // calculate the amount of DCB required to stake in the pool by scaling down the number by the tier boost
            const required_dcb_with_boost = _requiredDCB / (1 + r);

            // calculate the amount to deposit, for liquidity pools we need to deposit half the required amount in base tokens and the other half in quote i.e USDT, WBNB etc
            const amount = is_liquidity
                ? (required_dcb_with_boost * 1.05) / 2
                : required_dcb_with_boost;

            // set the deposit amount
            dispatch(setDepositAmountHandler(commify(amount)));
            // set the current pool id for the staking modal
            dispatch(setCurrentPool(pool.id));
            // close the upgrade drawer
            handleClose();
            // open the staking modal
            dispatch(setStakingModalOpen(true));
            // if the users wallet is connected, update the pool to get the users stake information if they already have some
            if (account) {
                dispatch(
                    updatePool(pool.id, pool.type, account, contract_manager),
                );
            }
        },
        [dispatch, account, contract_manager, _requiredDCB, handleClose],
    );

    const pool_arr: PoolProps[] = useMemo(
        () =>
            filtered_by_version
                .filter(({ end_date }) => dayjs(end_date).diff(dayjs()) > 0)
                .map((pool) => ({
                    ...getPoolItem(pool, dispatch),
                    handlePoolClick: () =>
                        handleSelectPoolInUpgradeTierDrawer(pool),
                })),
        [dispatch, handleSelectPoolInUpgradeTierDrawer],
    );

    useEffect(() => {
        setValue(is_max ? _userTier?.multiplier + 1 : 1);
    }, [is_max, _userTier?.multiplier]);

    return {
        show: _tierDrawerOpen,
        token_symbol: BaseTokenSymbol,
        handleClose,
        value,
        setValue: (v) => setValue(Math.max(initial_multiplier_val, v)),
        setStage: (stage) => _setTierUpgradeStage(stage),
        stage: _tierUpgradeStage,
        current_tier_info: {
            tier_name: current_tier?.name || 'No Tier',
            img_url: current_tier?.image_url || CONTENT?.tiers?.NoTierImage,
            is_max_tier: is_max,
            multiplier: _userTier?.multiplier || 1,
            tier_color: tierColorUI(_userTier?.multiplier || 1) as TierColors,
        },
        tier_info,
        pool_arr,
        min_value: initial_multiplier_val,
        upgrade_text:
            'Upgrade your investor tier to increase your allocation and unlock additional perks.',
    };
};
